import { type LocationOption as LocationOptionType } from '#imports'
import { type VacancyStatus as VacancyStatusType } from '#imports'
import { type VacancyType as VacancyTypeType } from '#imports'
import { type Frequency as FrequencyType } from '#imports'
import { type TimeOfDay as TimeOfDayType } from '#imports'

export enum VacancyStatus {
  Draft = 'draft',
  Published = 'published',
  Expired = 'expired',
  Filled = 'filled',
  Archived = 'archived',
}

export interface Vacancy extends BaseVacancy {
  status: VacancyStatusType
  vacancyType: VacancyTypeType
  audienceIds?: id[]
  categoryIds?: id[]
  activityTypeIds?: id[]
  locationOption?: LocationOptionType
  isEvent?: boolean
  dates?: string[] | null
  frequency?: FrequencyType | null
  availabilityDetails?: string | null
  timeOfDay?: TimeOfDayType[] | null
  timePeriod?: number | null
  dutchRequired: boolean
  vogRequired: boolean
  otherRequirement?: string | null
  openEntry?: boolean
  openEntryNote?: string | null
  maxApplicants?: number | null
  availableSlots?: number | null
  isIndividualP2P: boolean
  contactPhoneVisible: boolean
  volunteerCenterIds?: id[]
  // Timestamps
  visibilityEndDate?: Date | null
  publishedAt?: Date | null
  // Meedoen
  forMeedoen: boolean
  // Meta
  deedmobImportId?: number
  deedmobUrl?: string
}

interface VacancyRelationships {
  audiences: Audience[]
  categories: Category[]
  activityTypes: ActivityType[]
  volunteerCenters: VolunteerCenter[]
  organization: Organization
  address: Address
}

export async function fetchVacancy(id: id) {
  const {
    data,
    attributes: vacancy,
    pending,
    refresh,
    relationships,
  } = await useApiFetch<Vacancy, VacancyRelationships>(
    `/api/v1/vacancies/${id}?include=organization,address,categories,activity_types,audiences,volunteer_centers`,
  )

  // Quickfix to set id fields
  const v = vacancy.value
  if (v) {
    v.activityTypeIds = relationships.value.activityTypes.map(a => a.id)
    v.categoryIds = relationships.value.categories.map(c => c.id)
    v.audienceIds = relationships.value.audiences.map(a => a.id)
    v.volunteerCenterIds = relationships.value.volunteerCenters.map(a => a.id)
    // TODO: remove this, use relationships instead. Haven't removed it yet because I'm not sure what will break.
    v.address = relationships.value.address
  }

  return { data, vacancy, relationships, pending, refresh }
}

export function fetchVacanciesNew(query?: SearchParameters) {
  return useJsonApiFetch<Vacancy[]>('/api/v1/vacancies?include=organization,address', { query })
}

/**
 * @deprecated This function is deprecated and will be removed in future releases.
 * Please use fetchVacanciesNew instead.
 */
export async function fetchVacancies(query?: SearchParameters) {
  const {
    attributes: vacancies,
    pending,
    refresh,
    data,
    error,
  } = await useApiFetch<Vacancy[], { organization: Organization, address: Address }>(
    '/api/v1/vacancies?include=organization,address',
    {
      query,
    },
    false,
  )

  return {
    vacancies,
    pending,
    refresh,
    data,
    error,
  }
}

export function fetchProfileMatches() {
  return useJsonApiFetch<Vacancy[]>('/api/v1/volunteer_profiles/matches')
}

export interface VacancyPayload
  extends Omit<
    Vacancy,
    | 'id'
    | 'type'
    | 'isIndividualP2P'
    | 'createdAt'
    | 'updatedAt'
    | 'organizationName'
    | 'organizationLogoUrl'
    | 'contactPersonFirstName'
    | 'contactPersonLastName'
    | 'contactPersonAvatarUrl'
  > {}

export async function createVacancy(attributes: VacancyPayload) {
  const { data: vacancy } = await $api<JSONAPIResponse<Vacancy>>(
    '/api/v1/vacancies',
    {
      method: 'POST',
      body: objectToFormData(attributes, new FormData(), 'data[attributes]'),
    },
  )

  return vacancy
}

export async function updateVacancy(id: id, attributes: VacancyPayload) {
  const { data: vacancy } = await $api<JSONAPIResponse<Vacancy>>(
    `/api/v1/vacancies/${id}`,
    {
      method: 'PATCH',
      body: objectToFormData(attributes, new FormData(), 'data[attributes]'),
    },
  )

  return vacancy
}

export async function deleteVacancy(id: id) {
  await $api<JSONAPIResponse<Vacancy>>(`/api/v1/vacancies/${id}`, {
    method: 'DELETE',
  })
}
